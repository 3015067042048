import logo from "../assets/img/logo.png";
import "../styles/footer.css";
import { FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram, AiFillGithub } from "react-icons/ai";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <h2>EXPLORE</h2>

        <a href="/#">Homepage</a>

        <a href="/#about">About</a>

        <a href="/#skills">Skills</a>

        <a href="/#project">Projects</a>

        <a href="/contact">Contact</a>
      </div>

      <div className="footer-content">
        <h2>INFORMATION</h2>

        <p>
          {" "}
          <b> Whatsapp:</b> <a href="tel:+91 6207379815"> +(91) 6207379815</a>
        </p>

        <p>
          {" "}
          <b>Email: </b> <a href="mailto:info@bkchoubey.tech">info@bkchoubey.tech</a>
        </p>

        <div className="footer-social-icon">
          <a href="https://github.com/jonasmachados">
            <i>
              <AiFillGithub />
            </i>
          </a>

          <a href="https://www.instagram.com/bk_choubey_001/">
            <i>
              {" "}
              <AiFillInstagram />
            </i>
          </a>

          <a href="https://www.linkedin.com/in/bk-choubey-927a05255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
            <i>
              <FaLinkedinIn />
            </i>
          </a>
        </div>
      </div>

      <div className="footer-content">
        <img src={logo} alt="Logo" className="footer-logo" />

        <h2>&copy; 2024 BK-CHOUBEY. All rights reserved.</h2>
      </div>
    </div>
  );
};

export default Footer;
