const DataAboutMe = [
    {
      title: 'Who am I?',
      content: 'My name is BK-CHOUBEY. I am 20 years old and born in KOLKATA - Currently, I Work in Swiggy as IT Support .',
    },
    {
      title: 'What do I do?',
      content: 'I am interested in web development. I develop websites using Java for the back-end and React for the front-end.',
    },
    {
      title: 'What is my educational background?',
      content: 'I have a degree in BACHELOR IN COMPUTER APPLICATION (BCA) from Aryabhatta Knowledge University (PATNA).',
    },
  ];

  export default DataAboutMe;