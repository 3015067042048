import { motion } from "framer-motion";
import "../styles/svg.css";

const containerError404 = {
  hidden: { opacity: 0, rotate: 0 },
  visible: {
    opacity: 1,
    rotate: 360,
    transition: {
      duration: 1.5,
      ease: "easeIn",
    },
  },
};

const messageErrorVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 1,
      duration: 1.5,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

const SvgError404 = () => {
  const polygonStyle = { opacity: 0.2 };

  return (
    <div>
      <motion.svg
        className="error-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        variants={containerError404}
        initial="hidden"
        animate="visible"
      >
        <g id="freepik--Shadow--inject-2">
          <ellipse
            id="freepik--path--inject-2"
            cx="250"
            cy="416.24"
            rx="193.89"
            ry="11.32"
            fill="#f5f5f5"
          ></ellipse>
        </g>
        <g id="freepik--error-404--inject-2">
          <path
            d="M128.68,171.58l-.18,0c-4.5-1.75-7.19-3.45-8.59-5.5a5.14,5.14,0,0,1-3.23-.44,6.5,6.5,0,0,1-2.79-2.62,2.54,2.54,0,0,1-2.42.37,6.14,6.14,0,0,1-3-2.41,2.23,2.23,0,0,1-1.56.43c-3-.24-7-6.16-7.29-12.46a.52.52,0,0,1,.48-.53.5.5,0,0,1,.52.48c.28,6,4.11,11.33,6.38,11.51a1.26,1.26,0,0,0,.93-.27,12.28,12.28,0,0,1-1-9.42c.86-3,1.62-4.18,2.54-4,1.72.43,1.49,7,.58,11.29a6.14,6.14,0,0,1-.84,2.18,5.4,5.4,0,0,0,2.54,2.21,1.58,1.58,0,0,0,1.67-.35c-.78-1.93-1-4.57.12-7.93,1.4-4.43,2-4.55,2.31-4.63a.66.66,0,0,1,.64.22c.93,1.05-.18,7.93-1.34,11.33a5,5,0,0,1-.56,1.16,5.56,5.56,0,0,0,2.49,2.5,4.41,4.41,0,0,0,2.28.43c-.09-.19-.17-.38-.25-.58-.79-2.15-.4-4.62,1.19-7.58,1.05-1.95,1.91-2.87,2.71-2.87h0a1.11,1.11,0,0,1,1,.66c.87,1.61.24,6.92-1.48,9.56a4,4,0,0,1-1.62,1.4c1.34,1.76,3.89,3.31,8,4.89a.49.49,0,0,1,.29.64A.51.51,0,0,1,128.68,171.58ZM123,155.11s-.57,0-1.83,2.35c-1.45,2.68-1.82,4.89-1.13,6.75a5.06,5.06,0,0,0,.3.67,2.88,2.88,0,0,0,1.32-1.1c1.4-2.14,2.07-6.54,1.55-8.26C123.14,155.17,123,155.11,123,155.11Zm-7.18-4.21a23.33,23.33,0,0,0-1.34,3.53,11.38,11.38,0,0,0-.38,6.55l.09-.26A32.79,32.79,0,0,0,115.85,150.9Zm-6.58-3.15c-.17.15-.66.78-1.35,3.22a11.24,11.24,0,0,0,.72,8.24,6.18,6.18,0,0,0,.45-1.4C110.13,152.9,109.83,148.46,109.27,147.75Z"
            fill="#263238"
          ></path>
          <path
            d="M166.7,224a9,9,0,0,1-4.56-1.19.51.51,0,0,1-.18-.69.49.49,0,0,1,.68-.17c3.57,2.09,8.18.63,9.13-.9a.88.88,0,0,0,.15-.67,8.12,8.12,0,0,1-5.13-1.46c-2.55-1.59-2.75-2.07-2.73-2.45a.61.61,0,0,1,.34-.52c1-.5,5.51,1.57,7.39,2.81a3.63,3.63,0,0,1,.62.52,3.55,3.55,0,0,0,1.9-1.14,2.87,2.87,0,0,0,.59-1.13l-.2,0c-1.6.16-3.2-.53-4.91-2.11-1.18-1.08-1.63-1.83-1.48-2.42a.9.9,0,0,1,.72-.65c1.38-.35,4.8,1.11,6.19,2.64a2.68,2.68,0,0,1,.64,1.18c1.31-.61,2.69-2,4.32-4.27a.5.5,0,0,1,.7-.12.51.51,0,0,1,.12.7c-1.89,2.64-3.47,4.14-5.05,4.75a3.69,3.69,0,0,1-.87,2,4.6,4.6,0,0,1-2.18,1.41,1.84,1.84,0,0,1-.28,1.39C171.78,222.94,169.36,224,166.7,224Zm-.89-6.94c.34.25.82.58,1.51,1a8,8,0,0,0,3.6,1.32A24.67,24.67,0,0,0,165.81,217.06Zm3.49-4.25a4.33,4.33,0,0,0,1.17,1.39c1.48,1.37,2.83,2,4.13,1.84l.32,0a1.81,1.81,0,0,0-.44-.83C173.08,213.63,170,212.65,169.3,212.81Z"
            fill="#263238"
          ></path>
          <path
            d="M179.94,222.63l-15.58,2.83-14.15-77.22-30.06,5.46-49,87,5.26,28.71L139.26,258l4.3,23.48L173.62,276l-4.3-23.48,15.58-2.83ZM101.09,237l26-45.57,7.25,39.54Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M305.43,143a58.84,58.84,0,0,0-3.2-14.22,46.31,46.31,0,0,0-7-12.19,40.37,40.37,0,0,0-12.7-10.36q-8-4.39-21.22-6.11-27.45-3.58-42.78,9.74t-20.2,51a139.33,139.33,0,0,0-1,28.34q1,13,4.78,20.93a43.74,43.74,0,0,0,14.65,17.78q9.47,6.65,26,8.79,29.38,3.83,43.57-11.29t18.8-51.1A129.48,129.48,0,0,0,305.43,143Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M251.77,226.43c-2.41-.08-5-.3-7.61-.64-9.16-1.19-16.24-3.51-21.06-6.89a32.66,32.66,0,0,1-11-13.4l-.05-.11c-1.92-4-3.18-9.75-3.73-17a128.2,128.2,0,0,1,.92-26.1c3.57-27.72,10.94-39.29,16.49-44.11,6.48-5.62,15.23-8.16,26.73-7.76,2.36.08,4.86.29,7.43.62,7.38,1,13.23,2.6,17.37,4.86a29.79,29.79,0,0,1,9.31,7.49,35.25,35.25,0,0,1,5.35,9.27,47.78,47.78,0,0,1,2.56,11.55,118.67,118.67,0,0,1-.29,28.72c-2.77,21.54-8.13,36.68-15.92,45-5.78,6.16-14.45,9-26.51,8.54Z"
            fill="#263238"
          ></path>
          <path
            d="M271.34,143.29l.13-3.79-2.33-.76a22.82,22.82,0,0,0-.62-3.8l2-1.46-1.34-3.56-2.44.2a22.82,22.82,0,0,0-2-3.27l1.26-2.11-2.6-2.77-2.18,1.12a23.17,23.17,0,0,0-3.12-2.26l.35-2.42-3.46-1.57-1.58,1.86a23.26,23.26,0,0,0-3.75-.88l-.59-2.37-3.8-.13-.75,2.32a23.54,23.54,0,0,0-3.8.62l-1.47-2-3.56,1.33.2,2.44a22.94,22.94,0,0,0-3.27,2l-2.1-1.27-2.78,2.59,1.12,2.19a21.78,21.78,0,0,0-2.25,3.13l-2.42-.37-1.58,3.46,1.87,1.58a21.43,21.43,0,0,0-.88,3.76l-2.38.6-.13,3.78,2.33.76a22.6,22.6,0,0,0,.62,3.81l-2,1.45,1.34,3.56,2.44-.2a21.51,21.51,0,0,0,2,3.27l-1.27,2.11,2.6,2.77,2.18-1.11a23.1,23.1,0,0,0,3.12,2.25l-.36,2.42,3.46,1.57,1.59-1.85a23.12,23.12,0,0,0,3.76.87l.59,2.38,3.79.13.75-2.33a22.6,22.6,0,0,0,3.81-.62l1.45,2,3.56-1.33-.19-2.43a24.89,24.89,0,0,0,3.27-2l2.1,1.26,2.78-2.59-1.11-2.19a21.62,21.62,0,0,0,2.24-3.12l2.42.36,1.58-3.46-1.86-1.58a22.23,22.23,0,0,0,.87-3.75Zm-25.6,12.27a15,15,0,1,1,15.54-14.51A15,15,0,0,1,245.74,155.56Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M287.12,212.87l.28-2.56-1.54-.62a16,16,0,0,0-.22-2.6l1.39-.89-.71-2.47-1.66,0a14.82,14.82,0,0,0-1.19-2.31l.94-1.36-1.6-2-1.53.64a16.07,16.07,0,0,0-2-1.68l.37-1.62-2.25-1.24-1.18,1.19a15.87,15.87,0,0,0-2.48-.79l-.28-1.63-2.55-.29-.63,1.54a14.85,14.85,0,0,0-2.59.24l-.89-1.42-2.47.73v1.66a14.9,14.9,0,0,0-2.31,1.2l-1.36-1-2,1.6.64,1.53a16.13,16.13,0,0,0-1.67,2l-1.62-.36-1.23,2.25,1.18,1.17a15,15,0,0,0-.79,2.49l-1.64.27-.27,2.55,1.53.64a14.73,14.73,0,0,0,.23,2.58l-1.41.89.72,2.47H256a15.22,15.22,0,0,0,1.2,2.3l-1,1.36,1.6,2,1.55-.65a14.55,14.55,0,0,0,2,1.67L261,224l2.25,1.23,1.17-1.17a16.12,16.12,0,0,0,2.48.77l.29,1.64,2.55.28.62-1.53A16.22,16.22,0,0,0,273,225l.89,1.41,2.46-.72v-1.66a16.45,16.45,0,0,0,2.32-1.2l1.34,1,2-1.61-.65-1.53a16.28,16.28,0,0,0,1.69-2l1.61.36,1.24-2.25-1.18-1.16a16.47,16.47,0,0,0,.77-2.48Zm-12.87-1a4.46,4.46,0,1,1-1.77-6.05A4.47,4.47,0,0,1,274.25,211.87Z"
            fill="var(--primary-color);opacity:0.30000000000000004"
          ></path>
          <path
            d="M301.29,169.65l-3-2.06h0a16.59,16.59,0,0,0-1.2-3.1l.76-3-4.32-4.63-3,.56a17,17,0,0,0-3-1.41h0l-1.85-3.12-6.33-.21-2.06,3h0a15.92,15.92,0,0,0-3.1,1.2l-3-.77-4.63,4.33.56,3h0a15.68,15.68,0,0,0-1.41,3l-3.11,1.85-.22,6.33,3,2.06a16.11,16.11,0,0,0,1.2,3.12h0l-.76,3,4.32,4.63,3-.55a17.16,17.16,0,0,0,3,1.41h0l1.84,3.11,6.34.22,2.05-3a16.61,16.61,0,0,0,3.13-1.2h0l3,.76,4.63-4.32-.55-3a16.7,16.7,0,0,0,1.41-3h0l3.11-1.85Zm-19.73,9.62a7.12,7.12,0,1,1,7.36-6.87A7.13,7.13,0,0,1,281.56,179.27Z"
            fill="#fff"
          ></path>
          <path
            d="M323,119.12l.52-1.25h0a6.7,6.7,0,0,0,1-.69l1.16,0,1.36-2-.44-1.07A6.18,6.18,0,0,0,327,113h0l1-.92-.42-2.35-1.26-.52h0a6.35,6.35,0,0,0-.69-1l0-1.16-1.95-1.36-1.08.45h0a5.55,5.55,0,0,0-1.22-.28l-.92-1-2.34.42-.53,1.26a6.5,6.5,0,0,0-1.05.68h0l-1.15,0-1.36,2,.44,1.07a6.18,6.18,0,0,0-.28,1.22h0l-1,.93.42,2.34,1.26.52a5.73,5.73,0,0,0,.68,1h0l0,1.15,2,1.36,1.07-.44a6.43,6.43,0,0,0,1.23.27h0l.92,1ZM318,112.64a2.68,2.68,0,1,1,3.11,2.16A2.69,2.69,0,0,1,318,112.64Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M295.61,112.49l-.77-.65a19.9,19.9,0,0,1,6-5l.47.88A18.87,18.87,0,0,0,295.61,112.49Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M303.59,106.8l-.39-.92A23.92,23.92,0,0,1,314.94,104l-.1,1A22.79,22.79,0,0,0,303.59,106.8Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M301.7,113.72l-.82-.56a16.74,16.74,0,0,1,9.87-6.39l.19,1A15.72,15.72,0,0,0,301.7,113.72Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M235.34,210a.52.52,0,0,1-.36-.16l-2.09-2.16a17.49,17.49,0,0,1-3.15-.58l-3.27,1.44a.49.49,0,0,1-.47,0l-5.48-3.63a.49.49,0,0,1-.22-.43l0-3.56a17.2,17.2,0,0,1-1.76-2.68l-2.81-1.08a.5.5,0,0,1-.31-.37l-1.3-6.44a.5.5,0,0,1,.14-.46l2.16-2.09a18.78,18.78,0,0,1,.58-3.15l-1.44-3.27a.49.49,0,0,1,0-.47l3.63-5.48a.47.47,0,0,1,.43-.22l3.56.05a16.52,16.52,0,0,1,2.68-1.76l1.09-2.81a.49.49,0,0,1,.36-.31l6.44-1.3a.49.49,0,0,1,.46.14l2.09,2.16a17.76,17.76,0,0,1,3.16.58l3.26-1.44a.51.51,0,0,1,.48,0l5.47,3.63a.54.54,0,0,1,.23.43l-.06,3.56a17.26,17.26,0,0,1,1.77,2.68l2.8,1.09a.49.49,0,0,1,.31.36l1.31,6.44a.52.52,0,0,1-.14.46l-2.17,2.1a18,18,0,0,1-.58,3.14l1.44,3.27a.51.51,0,0,1,0,.48L250,203.59a.51.51,0,0,1-.42.22l-3.57-.05a18.38,18.38,0,0,1-2.67,1.76l-1.09,2.81a.49.49,0,0,1-.37.31L235.44,210ZM229.71,206a.36.36,0,0,1,.14,0,16.66,16.66,0,0,0,3.31.61.56.56,0,0,1,.32.15l2,2.1,5.9-1.19,1.06-2.74a.55.55,0,0,1,.24-.26,16.05,16.05,0,0,0,2.81-1.85.47.47,0,0,1,.33-.11l3.47.05,3.32-5-1.4-3.18a.5.5,0,0,1,0-.34,17.49,17.49,0,0,0,.61-3.31.51.51,0,0,1,.15-.32l2.1-2-1.19-5.9-2.73-1.06a.49.49,0,0,1-.27-.24,16.43,16.43,0,0,0-1.85-2.81.52.52,0,0,1-.11-.32l0-3.48-5-3.32-3.17,1.4a.52.52,0,0,1-.35,0,17,17,0,0,0-3.32-.61.56.56,0,0,1-.32-.15l-2-2.1-5.9,1.2L226.81,174a.51.51,0,0,1-.23.27,15.94,15.94,0,0,0-2.82,1.85.43.43,0,0,1-.32.11l-3.47-.05-3.33,5,1.4,3.18a.5.5,0,0,1,0,.43,17.13,17.13,0,0,0-.58,3.23.5.5,0,0,1-.15.32l-2.1,2,1.2,5.9,2.72,1.06a.55.55,0,0,1,.28.26,16.22,16.22,0,0,0,1.84,2.79.48.48,0,0,1,.11.32l0,3.48,5,3.32,3.18-1.4A.52.52,0,0,1,229.71,206Zm4.91-3.24a13.32,13.32,0,0,1-11.09-20.68h0a13.33,13.33,0,1,1,11.09,20.68Zm-10.25-20.13a12.32,12.32,0,1,0,17.08-3.46,12.27,12.27,0,0,0-17.08,3.46Z"
            fill="#fff"
          ></path>
          <path
            d="M425.83,231.65l-15.7-2.12,10.38-77.81-30.28-4.08-73.45,67.62-3.86,28.93,63.29,8.54-3.16,23.67,30.28,4.08,3.16-23.66,15.7,2.12Zm-79.42-10.72,38.76-35.33-5.32,39.84Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M394.13,279.74H394a.5.5,0,0,1-.4-.58L417,151.22a.51.51,0,0,1,.58-.4.5.5,0,0,1,.4.58L394.63,279.33A.51.51,0,0,1,394.13,279.74Z"
            fill="#263238"
          ></path>
          <path
            d="M414.83,153.37a.82.82,0,0,1-1.64-.06.82.82,0,0,1,1.64.06Z"
            fill="#263238"
          ></path>
          <path
            d="M411.5,170.67a.82.82,0,1,1-.79-.85A.82.82,0,0,1,411.5,170.67Z"
            fill="#263238"
          ></path>
          <path
            d="M408.17,188a.82.82,0,0,1-.85.79.81.81,0,0,1-.79-.84.82.82,0,0,1,.85-.8A.83.83,0,0,1,408.17,188Z"
            fill="#263238"
          ></path>
          <path
            d="M401.61,206a.82.82,0,0,1-1.63,0,.82.82,0,1,1,1.63,0Z"
            fill="#263238"
          ></path>
          <path
            d="M401.5,222.57a.81.81,0,0,1-.85.79.82.82,0,1,1,.85-.79Z"
            fill="#263238"
          ></path>
          <path
            d="M398.17,239.87a.82.82,0,1,1-.79-.84A.82.82,0,0,1,398.17,239.87Z"
            fill="#263238"
          ></path>
          <path
            d="M394.84,257.17a.83.83,0,0,1-.85.8.82.82,0,0,1-.79-.85.82.82,0,0,1,1.64.05Z"
            fill="#263238"
          ></path>
          <path
            d="M391.5,274.48a.82.82,0,1,1-.79-.85A.81.81,0,0,1,391.5,274.48Z"
            fill="#263238"
          ></path>
          <path
            d="M400.79,206.45a.49.49,0,0,1-.48-.39.5.5,0,0,1,.38-.6l2.48-.53a.5.5,0,0,1,.6.39.5.5,0,0,1-.39.59l-2.48.53Z"
            fill="#263238"
          ></path>
          <path
            d="M409.08,197.24a85.06,85.06,0,0,0-3,9.36c-.75,3.53-.79,11.43-.79,11.43"
            fill="#263238"
          ></path>
          <path
            d="M405.29,218.53h0a.52.52,0,0,1-.5-.51c0-.32.06-8,.81-11.52a84.22,84.22,0,0,1,3-9.44.5.5,0,1,1,.93.36,84.37,84.37,0,0,0-3,9.29c-.73,3.44-.78,11.24-.79,11.32A.5.5,0,0,1,405.29,218.53Z"
            fill="#263238"
          ></path>
        </g>
        <g id="freepik--Character--inject-2">
          <path
            d="M222.45,304.84c1.39-22.4-1.16-63.13,40.95-57.73,45.13,5.77,32.22,
            58.55,32.22,58.55Z"
            fill="#263238"
          ></path>
          <circle cx="251.51" cy="273" r="18.38" fill="#fff"></circle>
          <path
            d="M253.93,254.65c-8.71-1.48-18,4.93-18.93,11.43l34.51,3.18C268.29,
            261.85,260.83,255.83,253.93,254.65Z"
            fill="#263238"
          ></path>
          <path
            d="M265.58,272.54c-.25,4.81-2.87,8.58-5.85,8.42s-5.2-4.17-5-9,2.87-8.58,
            5.85-8.42S265.82,267.74,265.58,272.54Z"
            fill="#263238"
          ></path>
          <path
            d="M251.51,292.38A19.38,19.38,0,1,1,270.89,273,19.4,19.4,0,0,1,251.51,
            292.38Zm0-36.75A17.38,17.38,0,1,0,268.89,273,17.39,17.39,0,0,0,251.51,255.63Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M290.49,304.28l-.12,0a.49.49,0,0,1-.37-.6c0-.13,3.22-13.69-.09-27.4a.5.5,
            0,1,1,1-.24c3.37,14,.12,27.74.09,27.87A.5.5,0,0,1,290.49,304.28Z"
            fill="#fff;opacity:0.30000000000000004"
          ></path>
          <path
            d="M287.7,268.76a.52.52,0,0,1-.46-.29,34.93,34.93,0,0,0-4.74-7.66.5.5,0,0,1,
            .07-.7.5.5,0,0,1,.71.07,36.47,36.47,0,0,1,4.87,7.87.49.49,0,0,1-.24.66A.54.54,
            0,0,1,287.7,268.76Z"
            fill="#fff;opacity:0.30000000000000004"
          ></path>
          <path
            d="M128.73,409.92h-.37a5,5,0,1,1,.28-10h.09c2.89,0,9.84-3.51,24.38-26.43a5,
            5,0,0,1,7.51-1.09,207.46,207.46,0,0,0,37.14,25.48,5,5,0,1,1-4.52,8.91,212.75,
            212.75,0,0,1-34.8-23.14C146.25,402,137.21,409.92,128.73,409.92Z"
            fill="#263238"
          ></path>
          <path
            d="M159.67,371.5l-.43,0a3,3,0,0,1-2.54-3.39c.71-5-.17-23.63-6.48-38.13a3,3,0,
            0,1,1.06-3.68c13.7-9.33,42.88-10.45,44.12-10.49a3,3,0,1,1,.2,6c-.27,0-25.78,
            1-38.88,8.2,5.58,14.44,6.85,32.43,5.92,38.94A3,3,0,0,1,159.67,371.5Z"
            fill="#263238"
          ></path>
          <path
            d="M164.71,367.62c-2-4.71-11-4.18-16.27-.14-2.82,2.15-6.64,8.64-7,11a3.54,
            3.54,0,0,0,4,4.24c3.45-.3,8.18-5.31,8.18-5.31-3.15,3.09.92,5.27,7,.8C163.94,
            375.72,166.94,372.89,164.71,367.62Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M336.71,306.16a.32.32,0,0,1-.14,0,.49.49,0,0,1-.34-.62c1.33-4.64,2.78-7.47,
            4.69-9a5.18,5.18,0,0,1,.16-3.26,6.45,6.45,0,0,1,2.36-3,2.54,2.54,0,0,
            1-.59-2.38,6.18,6.18,0,0,1,2.14-3.16,2.24,2.24,0,0,1-.57-1.51c0-3,5.51-7.52,
            11.76-8.38a.5.5,0,0,1,.14,1c-5.94.81-10.92,5.11-10.9,7.38a1.31,1.31,0,0,0,
            .36.91,12.24,12.24,0,0,1,9.28-1.87c3.07.59,4.32,1.24,4.18,2.17-.28,1.75-6.81,
            2.12-11.19,1.6a6.18,6.18,0,0,1-2.25-.65,5.38,5.38,0,0,0-2,2.73,1.57,1.57,0,0,0,
            49,1.64c1.86-1,4.47-1.36,7.92-.6,4.53,1,4.7,1.55,4.81,1.88a.67.67,0,0,
            1-.17.67c-.95,1-7.9.53-11.39-.33a4.91,4.91,0,0,1-1.21-.45,5.45,5.45,0,0,
            0-2.26,2.71,4.23,4.23,0,0,0-.23,2.3,5.77,5.77,0,0,1,.55-.29c2.07-1,4.57-.82,
            7.66.5,2,.87,3,1.65,3.1,2.44a1.12,1.12,0,0,1-.57,1c-1.53,1-6.87.86-9.65-.62a4,
            4,0,0,1-1.54-1.49c-1.63,1.5-2.95,4.17-4.15,8.36A.51.51,0,0,1,336.71,
            306.16Zm5.42-9.33a3,3,0,0,0,1.22,1.22c2.26,1.2,6.7,1.47,
            8.36.8.34-.14.39-.25.39-.25s-.05-.56-2.5-1.61c-2.8-1.2-5-1.37-6.83-.52A5.79,
            5.79,0,0,0,342.13,296.83Zm3.34-6.57.25.07a33.09,33.09,0,0,0,9.94.75,21.7,21.7,
            0,0,0-3.64-1A11.38,11.38,0,0,0,345.47,290.26Zm1.26-5.62a5.92,5.92,0,0,0,
            1.43.32c5,.59,9.39-.1,10-.73-.17-.15-.84-.58-3.32-1.05A11.25,11.25,0,0,0,
            346.73,284.64Z"
            fill="#263238"
          ></path>
          <path
            d="M206.53,408.25c.66-.21.44-.85-.3-.85H192.69c-25.78-2.73-43.79.93-32.44,
            1.79s-.8.68-9.09,3.82,61.65,3.93,68,0C225,409.39,197.39,411.18,206.53,408.25Z"
            fill="#263238"
          ></path>
          <polygon
            points="303.47 412.24 185.67 408.33 180.78 294.11 319.89 297.22 303.47 412.24"
            fill="var(--primary-color)"
          ></polygon>
          <path
            d="M222.45,410.05a.5.5,0,0,1-.5-.46l-9.44-114.72a.51.51,0,0,1,.46-.54.52.52,
            0,0,1,.54.46L223,409.51a.5.5,0,0,1-.46.54Z"
            fill="#263238"
          ></path>
          <polygon
            points="303.47 412.24 335.13 407.42 345.1 301.21 319.89 297.22 303.47 412.24"
            fill="var(--primary-color)"
          ></polygon>
          <polygon
            points="303.47 412.24 335.13 407.42 345.1 301.21 319.89 297.22 303.47 412.24"
            style={polygonStyle}
          ></polygon>
          <path
            d="M195.73,386.32l-2.9-67.87a12.27,12.27,0,0,1,12.53-12.79l88.06,2a12.27,
            12.27,0,0,1,11.87,14L295.53,390A12.27,12.27,0,0,1,283,400.55l-75.39-2.5A12.27,
            12.27,0,0,1,195.73,386.32Z"
            fill="#263238"
          ></path>
          <motion.path
            d="M205.25,326.49l13.62.43-.11,3.51-8.53-.27-.08,2.62,7.91.25-.11,
            3.36-7.91-.26-.1,3.25,8.77.28-.12,3.72-13.87-.44Z"
            fill="var(--primary-color)"
            variants={messageErrorVariants}
            initial="hidden"
            animate="visible"
          ></motion.path>
          <motion.path
            d="M221.4,343.47l.52-16.45,8.48.27a12.22,12.22,0,0,1,3.59.52,3.81,3.81,0,0,1,
            2,1.56,4.64,4.64,0,0,1-1.64,6.59,6.16,6.16,0,0,1-1.81.6,5.12,5.12,0,0,1,
            1.31.64,5.51,5.51,0,0,1,.78.9,6.43,6.43,0,0,1,.68,1l2.31,
            4.85-5.74-.19-2.56-5.11a3.94,3.94,0,0,0-.88-1.3,2.22,2.22,0,0,
            0-1.23-.42h-.45l-.21,6.67Zm5.42-9.62,2.14.07a8.63,8.63,0,0,0,1.35-.19,1.35,
            1.35,0,0,0,.85-.49,1.57,1.57,0,0,0,.35-.94,1.54,1.54,0,0,0-.47-1.24,3.1,3.1,0
            ,0,0-1.88-.49l-2.24-.07Z"
            fill="var(--primary-color)"
            variants={messageErrorVariants}
            initial="hidden"
            animate="visible"
          ></motion.path>
          <motion.path
            d="M239.28,344l.52-16.45,8.47.27a12.16,12.16,0,0,1,3.59.52,3.77,3.77,0,0,1,2,
            1.56,4.45,4.45,0,0,1,.68,2.69,4.42,4.42,0,0,1-2.31,3.9,6.27,6.27,0,0,
            1-1.81.6,5,5,0,0,1,1.3.64,4.84,4.84,0,0,1,.79.89,6.87,6.87,0,0,1,.68,1l2.31,
            4.84-5.74-.18-2.56-5.11a4,4,0,0,0-.88-1.3,2.08,2.08,0,0,0-1.24-.42l-.44,0-.22,
            6.68Zm5.41-9.62,2.15.06a7.21,7.21,0,0,0,1.35-.18,1.31,1.31,0,0,0,.84-.49,1.46,
            1.46,0,0,0,.35-.94,1.52,1.52,0,0,0-.46-1.24,3,3,0,0,0-1.89-.49l-2.23-.07Z"
            fill="var(--primary-color)"
            variants={messageErrorVariants}
            initial="hidden"
            animate="visible"
          ></motion.path>
          <motion.path
            d="M256.32,336.36a7.79,7.79,0,0,1,8.76-8.25,8.6,8.6,0,0,1,6.26,2.41,8.32,8.32,
            0,0,1,2,6.25,10.21,10.21,0,0,1-1.12,4.7,7,7,0,0,1-2.9,2.78,9.22,9.22,0,0,
            1-4.61.88,10.58,10.58,0,0,1-4.58-1,7,7,0,0,1-2.86-2.9A9.21,9.21,0,0,1,256.32,
            336.36Zm5.08.18a5.56,5.56,0,0,0,.81,3.61,3.52,3.52,0,0,0,5.06.19,6.13,6.13,0,
            0,0,1-3.8,5.17,5.17,0,0,0-.83-3.43A3.22,3.22,0,0,0,265,332a3.1,3.1,0,0,0-2.51,
            1A5.56,5.56,0,0,0,261.4,336.54Z"
            fill="var(--primary-color)"
            variants={messageErrorVariants}
            initial="hidden"
            animate="visible"
          ></motion.path>
          <motion.path
            d="M275.91,345.21l.52-16.46,8.47.27a12.51,12.51,0,0,1,3.59.52,3.87,3.87,0,0,
            1,2,1.56,4.49,4.49,0,0,1,.68,2.69,4.42,4.42,0,0,1-2.31,3.91,6.61,6.61,0,0,
            1-1.81.59,5,5,0,0,1,1.3.65,4.84,4.84,0,0,1,.79.89,6.43,6.43,0,0,1,.68,1l2.31,
            4.85-5.74-.18-2.56-5.12a3.91,3.91,0,0,0-.88-1.29,2.16,2.16,0,0,
            0-1.24-.43h-.44l-.22,6.68Zm5.41-9.63,2.15.07a7.21,7.21,0,0,0,1.35-.18,1.35,
            1.35,0,0,0,.84-.49,1.48,1.48,0,0,0,.35-1,1.5,1.5,0,0,0-.46-1.23,3,3,0,0,
            0-1.89-.49l-2.23-.07Z"
            fill="var(--primary-color)"
            variants={messageErrorVariants}
            initial="hidden"
            animate="visible"
          ></motion.path>
          <motion.path
            d="M284,373.16a1.32,1.32,0,0,1-1-.43l-8.56-9.37-9.24,8.44a1.31,1.31,0,0,
            1-1.85-.08l-8.56-9.37-9.24,8.44a1.31,1.31,0,0,1-1.85-.08l-8.57-9.37-9.23,
            8.44a1.33,1.33,0,0,1-1,.34,1.35,1.35,0,0,1-.91-.43l-8.56-9.36-9.23,8.43a1.32,
            1.32,0,0,1-1.86-.08,1.3,1.3,0,0,1,.09-1.85l10.2-9.33a1.32,1.32,0,0,1,
            1.86.08l8.56,9.37,9.23-8.44a1.37,1.37,0,0,1,.95-.34,1.33,1.33,0,0,1,
            .91.43l8.56,9.37,9.24-8.44a1.3,1.3,0,0,1,1.85.08l8.56,9.37,9.24-8.44a1.31,1.31,
            0,0,1,1.85.08L285,371a1.32,1.32,0,0,1-1,2.2Z"
            fill="var(--primary-color)"
            variants={messageErrorVariants}
            initial="hidden"
            animate="visible"
          ></motion.path>
          <polygon
            points="125.75 414.67 119.49 411.68 113.91 384.51 116.94 381.18 125.75 414.67"
            fill="var(--primary-color)"
          ></polygon>
          <polygon
            points="125.75 414.67 134.06 409.81 126.94 387.43 116.94 381.18 125.75 414.67"
            fill="var(--primary-color)"
          ></polygon>
          <polygon
            points="125.75 414.67 134.06 409.81 126.94 387.43 116.94 381.18 125.75 414.67"
            style={polygonStyle}
          ></polygon>
          <path
            d="M262,419.61h-.69a5,5,0,0,1,.27-10c7.7.25,24-4.48,33.32-13.52a5,5,0,0,1,
            2-1.2c15.79-4.74,21.66,4.83,21.9,5.24l-8.6,5.09a1,1,0,0,0,
            .11.18c-.09-.13-2.33-3-9.45-1.24C289.17,414.82,271.23,419.61,262,419.61Z"
            fill="#263238"
          ></path>
          <polygon
            points="261.39 426.25 243.18 425.11 241.75 391.53 266.22 392.44 261.39 426.25"
            fill="var(--primary-color)"
          ></polygon>
          <polygon
            points="261.39 426.25 265.65 422.01 268.29 401.08 266.22 392.44 261.39 426.25"
            fill="var(--primary-color)"
          ></polygon>
          <polygon
            points="261.39 426.25 265.65 422.01 268.29 401.08 266.22 392.44 261.39 426.25"
            style={polygonStyle}
          ></polygon>
          <path
            d="M366.91,413.54a3,3,0,0,1-1.81-.61c-10.4-7.9-24.45-21.93-29.84-35.46a2.64,
            2.64,0,0,1-.17-.61c-2.63-15.64-5.14-48.55-5.25-49.94a3,3,0,1,1,6-.45c0,.33,
            2.57,33.56,5.13,49.07,5.38,13.16,20,26.67,27.78,32.61a3,3,0,0,1-1.82,5.39Z"
            fill="#263238"
          ></path>
          <path
            d="M380.78,415.05c-2.32,0-4.95-1.05-7.82-3.12a3,3,0,0,1,3.52-4.86c2.48,
            1.79,3.83,2,4.3,2a3,3,0,0,1,0,6Z"
            fill="#263238"
          ></path>
          <path
            d="M373.8,411.84c3.21-9.53,16.37-7.65,20.91-6.23a47.77,47.77,0,0,1,11.07,
            4.8c2.45,1.6,2.49,4.29-.78,5.82-4,1.88-14.16.21-14.16.21,5.24,2.29,0,6-9.21,
            2.36C378.64,417.62,372.67,415.34,373.8,411.84Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M341.86,331c-1.19,8.1-7.9,14.07-13.36,13.27s-5.68-8-4.49-16.14,3.35-14,
            8.81-13.2S343.05,322.93,341.86,331Z"
            fill="#263238"
          ></path>
          <circle cx="209.88" cy="297.88" r="0.82" fill="#263238"></circle>
          <path
            d="M218.21,303.78a.82.82,0,1,1-.82-.82A.82.82,0,0,1,218.21,303.78Z"
            fill="#263238"
          ></path>
          <path
            d="M219.13,400.68a.82.82,0,1,1-.82-.82A.82.82,0,0,1,219.13,400.68Z"
            fill="#263238"
          ></path>
          <path
            d="M226.64,406.59a.82.82,0,1,1-.82-.82A.82.82,0,0,1,226.64,406.59Z"
            fill="#263238"
          ></path>
        </g>
        <g id="freepik--robot-parts--inject-2">
          <path
            d="M82.51,416.42a9.62,9.62,0,0,1-7.33-3.95.5.5,0,1,1,.82-.57c1.87,2.7,6,4.13,
            7.8,3.28a1.3,1.3,0,0,0,.47-.34,6.05,6.05,0,0,1-3.52-4.48c-.23-1.27-.41-3,
            .63-3.22,1.44-.32,3.32,3.15,4,5.78a3.78,3.78,0,0,1,.13,1.34,4.26,4.26,0,0,0,
            1.78.14,1.94,1.94,0,0,0,1.38-.74,5.86,5.86,0,0,
            1-2.31-4.19c-.38-2.74-.09-3.21.21-3.45a.66.66,0,0,1,.69-.07c1.07.45,2.36,4.33,
            2.61,6.53a2.66,2.66,0,0,1,0,.69,3.91,3.91,0,0,0,1.35.26,4.29,4.29,0,0,0,
            2.41-.63c-.14-.09-.27-.19-.4-.29-1-.89-1.46-2.31-1.28-4.33.14-1.57.49-2.43,
            1.12-2.7a1.13,1.13,0,0,1,1.21.24,6.92,6.92,0,0,1,1.73,5.56,3,3,0,0,1-.51,
            1.15c1.84.32,4.42-.2,6.65-.75a.5.5,0,0,1,.24,1c-2.71.67-5.63,1.22-7.69.62a5.69,
            5.69,0,0,1-3.5,1.16,4.83,4.83,0,0,1-1.64-.31,2.8,2.8,0,0,1-2.12,1.27,5.33,5.33,
            0,0,1-2.24-.19,2.21,2.21,0,0,1-1,.89A4.16,4.16,0,0,1,82.51,416.42Zm-.86-8.28a4.32,
            4.32,0,0,0,.08,2.05,5.05,5.05,0,0,0,2.84,3.7,3.21,3.21,0,0,0-.12-.7C83.67,
            410.33,82.17,408.36,81.65,408.14Zm5.57-.87a14.19,14.19,0,0,0,.17,2.06,5.18,
            5.18,0,0,0,1.52,3.22A15.72,15.72,0,0,0,87.22,407.27Zm6.29-.87h0s-.38.19-.52,
            1.86.15,2.82.93,3.49a3.06,3.06,0,0,0,.6.38,2.24,2.24,0,0,0,.54-1,6.26,6.26,0,
            0,0-1.19-4.43C93.66,406.44,93.54,406.4,93.51,406.4Z"
            fill="#263238"
          ></path>
          <polygon
            points="228.87 423.98 231.58 419.28 228.87 414.58 225.97 414.58 227.61 419.28
             225.97 423.98 228.87 423.98"
            fill="#263238"
          ></polygon>
          <path
            d="M226.16,421.73a2.45,2.45,0,1,1,0-4.89l-4.6-.92-1.23,3.72,2.06,3.14Z"
            fill="#263238"
          ></path>
          <path
            d="M226.56,414.58h-5.43l-2.71,4.7,2.71,4.7h5.43l2.71-4.7Zm-2.71,7.15a2.45,
            2.45,0,1,1,2.44-2.45A2.45,2.45,0,0,1,223.85,421.73Z"
            fill="var(--primary-color)"
          ></path>
          <polygon
            points="417.89 414.26 415.88 410.77 417.89 407.28 420.04 407.28 418.83 410.77 
            420.04 414.26 417.89 414.26"
            fill="var(--primary-color)"
          ></polygon>
          <path
            d="M419.9,412.59a1.82,1.82,0,1,0,0-3.63l3.42-.68.91,2.76-1.52,2.33Z"
            fill="var(--primary-color)"
          ></path>
          <path
            d="M419.61,407.28h4l2,3.49-2,3.49h-4l-2-3.49Zm2,5.31a1.82,1.82,0,1,
            0-1.81-1.82A1.81,1.81,0,0,0,421.62,412.59Z"
            fill="#263238"
          ></path>
          <path
            d="M343,418.9h4.52a2.14,2.14,0,0,0,2.13-2.14h0a2.14,2.14,0,0,
            0-2.13-2.14H343a2.14,2.14,0,0,0-2.14,2.14h0A2.14,2.14,0,0,0,343,418.9Z"
            fill="#263238"
          ></path>
          <path
            d="M335.73,414.86a4.46,4.46,0,1,1,2,6.18A4.61,4.61,0,0,1,335.73,414.86Z"
            fill="var(--primary-color)"
          ></path>
          <rect
            x="339.07"
            y="411.71"
            width="1.37"
            height="10.1"
            transform="translate(469.33 938.61) rotate(-154.76)"
            fill="#263238"
          ></rect>
        </g>
      </motion.svg>
    </div>
  );
};

export default SvgError404;
